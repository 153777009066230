<template>
  <div class="page-role">
    <mt-button class="mb15">
      <el-button @click="onAddBtnClick" type="primary">新增角色</el-button>
      <el-button @click="editRoleModule" type="primary">保存权限</el-button>
    </mt-button>
    <el-row :gutter="20">
      <el-col :span="8">
        <el-table
          ref="visitTable"
          :data="roleDataList"
          stripe
          :height="tableHeight"
          :maxHeight="tableHeight"
          :highlight-current-row="true"
          @row-click="onRoleClick"
          row-class-name="table-row-cursor"
          style="width: 100%;">
          <el-table-column
            header-align="left"
            type="index"
            label="#"
            :index="formatterIndex"
            width="50">
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="name"
            label="角色名称">
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            width="100"
            label="操作">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" content="编辑" placement="top">
                <el-button @click.stop="onEditBtnClick(scope.row)" type="primary" icon="el-icon-edit" circle></el-button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="删除" placement="top">
                <el-button @click.stop="onDeleteBtnClick(scope.row)" type="danger" icon="el-icon-delete" circle></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @current-change="onPageChange"
          layout="->, total, prev, pager, next"
          :page-size="pageSize"
          :current-page="pageIndex"
          :total="roleDataTotal"
          class="pagination">
        </el-pagination>
      </el-col>
      <el-col :span="8">
        <div class="role-name">{{selectRole.name || '请先选择角色'}}</div>
        <el-tree
          ref="moduleTree"
          :style="treeStyle"
          default-expand-all
          :data="moduleData"
          :show-checkbox="true"
          :props="treeProps"
          node-key="id">
        </el-tree>
        <!--        :default-checked-keys="selectModule"-->
      </el-col>
    </el-row>

    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%" :close-on-click-modal="false">
      <el-form ref="roleForm" :rules="rules" :model="roleModel" label-position="right" label-width="80px"
               style='width: 300px; margin:0 auto;'>
        <el-form-item label="名称" prop="name">
          <el-input v-model="roleModel.name"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="editRoleItem">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
  import MtButton from '../../components/mt-button'
  import {tableMixin} from '../../mixins/table'
  import {apiGetModuleTree} from '../../api/module'
  import {apiGetRoleList, apiGetRoleDetail, apiEditRole, apiDeleteById} from '../../api/role'

  export default {
    name: 'Role',
    mixins: [tableMixin],
    components: {MtButton},
    data() {
      return {
        roleDataList: [],
        roleDataTotal: 0,
        dialogVisible: false,
        dialogTitle: '',
        dialogType: 'add',
        roleModel: {
          id: 0,
          name: ''
        },
        rules: {
          name: [{required: true, message: '不能为空', trigger: 'blur'}]
        },
        selectRole: {},
        moduleData: [],
        canCheckedModuleIds: [],
        selectModule: [],
        treeProps: {
          label: 'name',
          children: 'children'
        }
      }
    },
    created() {
      this._getRoleList()
      this._getModuleList()
    },
    computed: {
      treeStyle() {
        let roleNameHeight = 0
        const roleNameDom = document.querySelector('.role-name')
        if (roleNameDom) {
          roleNameHeight = roleNameDom.offsetHeight
        }
        let treeHeight = this.tableHeight - roleNameHeight - 10
        return {
          height: `${treeHeight}px`,
          maxHeight: `${treeHeight}px`,
          overflowY: 'auto'
        }
      }
    },
    methods: {
      onPageChange(val) {
        this.pageIndex = val
        this._getRoleList('')
      },
      onAddBtnClick() {
        this.dialogVisible = true
        this.dialogType = 'add'
        this.dialogTitle = '新增角色'
        this._clearModel()
        this._resetModel()
      },
      onEditBtnClick(row) {
        this.dialogType = 'edit'
        this.dialogVisible = true
        this.dialogTitle = '编辑角色'
        this._clearModel()
        this.roleModel = {
          id: row.id,
          name: row.name
        }
      },
      editRoleModule() {
        if (!this.selectRole.id) {
          this.$message({
            type: 'warning',
            message: '请先选择角色'
          })
          return
        }
        const selectModule = this.$refs['moduleTree'].getCheckedNodes(false, true)
        this.selectRole.modules = selectModule
        this._editRole(this.selectRole, () => {
          this.$message({
            type: 'success',
            message: '权限修改成功'
          })
        })
      },
      editRoleItem() {
        this.$refs['roleForm'].validate((valid) => {
          if (valid) {
            this._editRole(this.roleModel, () => {
              this.pageIndex = 1
              this._getRoleList('')
              this.dialogVisible = false
              this._resetModel()
              this.$message({
                type: 'success',
                message: this.dialogType === 'add' ? '添加成功' : '修改成功'
              })
            })
          }
        })
      },
      onDeleteBtnClick(row) {
        this.$confirm('是否确认删除？', '提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          apiDeleteById(row.id).then(data => {
            this._getRoleList('')
            this.$message({
              type: 'success',
              message: '删除成功'
            })
          })
        })
      },
      onRoleClick(row, column, event) {
        this.selectRole = row
        apiGetRoleDetail(row.id).then(({data}) => {
          const modules = data.modules
          let modulesIds = []
          if (modules.length !== 0) {
            for (let i = 0; i < modules.length; i++) {
              const modulesId = modules[i].id
              if (this.canCheckedModuleIds.find(item => modulesId === item)) {
                modulesIds.push(modulesId)
              }
            }
          }
          this.selectModule = modulesIds
          this.$refs['moduleTree'].setCheckedKeys(modulesIds)
        })
      },
      _clearModel() {
        this.$nextTick(() => {
          this.$refs['roleForm'].clearValidate()
        })
      },
      _resetModel() {
        this.roleModel = {
          id: 0,
          name: ''
        }
      },
      _getRoleList(pageIndex = this.pageIndex) {
        apiGetRoleList(pageIndex).then(({data}) => {
          this.roleDataList = data.data
          this.roleDataTotal = data.total
        })
      },
      _editRole(params, cb) {
        apiEditRole(params).then(data => {
          if (cb && typeof cb === 'function') {
            cb()
          }
        })
      },
      _getModuleList() {
        apiGetModuleTree().then(({data}) => {
          this.moduleData = data
          this.canCheckedModuleIds = this._filterCanCheckedModuleIds(data)
        })
      },
      _filterCanCheckedModuleIds(moduleList) {
        moduleList = moduleList || this.moduleData
        let canCheckedModuleIds = []
        for (let i = 0; i < moduleList.length; i++) {
          const item = moduleList[i]
          if (item.children && item.children.length !== 0) {
            canCheckedModuleIds = canCheckedModuleIds.concat(this._filterCanCheckedModuleIds(item.children))
          } else {
            canCheckedModuleIds.push(item.id)
          }
        }
        return canCheckedModuleIds
      }
    }
  }
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>
  .page-role
    .role-name
      padding 5px
      margin-bottom 10px
      font-size 18px
      color #909399
      background-color #fff
</style>
