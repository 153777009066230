/**
 * Created by ZengFanhui on 2021/1/6 13:57
 */
import {HTTP_TYPE, PAGE_SIZE} from '../utils/constants'
import {doHttp} from '../utils/common'

/**
 * 获取权限树
 * @returns {*}
 */
export function apiGetModuleTree() {
  return doHttp('/module/tree.action', HTTP_TYPE.GET, {})
}
