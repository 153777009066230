/**
 * Created by ZengFanhui on 2021/1/6 9:13
 */
import {HTTP_TYPE, PAGE_SIZE} from '../utils/constants'
import {doHttp} from '../utils/common'

/**
 * 获取角色列表
 * @param pageIndex
 * @param pageSize
 * @returns {*}
 */
export function apiGetRoleList (pageIndex = 1, pageSize = PAGE_SIZE) {
  let params = {
    pageIndex,
    pageSize
  }
  return doHttp('/role/list.action', HTTP_TYPE.GET, params)
}

/**
 * 编辑角色
 * @param params
 * @returns {*}
 */
export function apiEditRole(params) {
  return doHttp('/role/edit.action', HTTP_TYPE.POST, params)
}

/**
 * 根据ID删除
 * @param id
 * @returns {*}
 */
export function apiDeleteById(id) {
  let params = {id}
  return doHttp('/role/delete.action', HTTP_TYPE.GET, params)
}

/**
 * 获取角色详情
 * @param roleId
 * @returns {*}
 */
export function apiGetRoleDetail(roleId) {
  let params = {roleId}
  return doHttp('/role/detail.action', HTTP_TYPE.GET, params)
}
